<template>
  <div>
    <CuentaUsuarioForm/>
  </div>
</template>
<script>
import CuentaUsuarioForm from "./components/CuentaUsuarioForm";
export default {
  components: {CuentaUsuarioForm}
}
</script>