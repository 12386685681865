<template>

  <div class="our-services section">

    <div class="container">
      <div class="row">
        <div
            class="col-lg-6 align-self-center wow fadeInLeft"
            data-wow-duration="0.5s"
            data-wow-delay="0.25s"
        >
          <div class="left-image">
            <img src="../../../assets/images/registro.png" alt=""/>
          </div>
        </div>

        <div
            class="col-lg-6 wow fadeInRight"
            data-wow-duration="0.5s"
            data-wow-delay="0.25s"
        >

          <div class="section-heading">
            <h2>Datos del cliente</h2>
          </div>

          <p>
            Revise si sus datos son correctos para solicitar la cuenta
          </p>

          <Form @submit="submit" id="contact" :validation-schema="formValidation" v-slot="{errors}">
            <div class="row">

              <div class="col-lg-12">
                <fieldset>
                  <Field
                      name="nombreComercial"
                      placeholder="Nombre Comercial"
                      disabled
                      v-model.trim="formData.nombreComercial"
                  />
                  <MensajeValidacion :mensaje="errors.nombreComercial"/>
                </fieldset>
              </div>

              <div class="col-lg-12">
                <fieldset>
                  <Field
                      name="nombre"
                      placeholder="Nombre"
                      disabled
                      v-model.trim="formData.nombre"
                  />
                  <MensajeValidacion :mensaje="errors.nombre"/>
                </fieldset>
              </div>
              <div class="col-lg-12">
                <fieldset>
                  <Field
                      name="correo"
                      placeholder="Email"
                      v-model.trim="formData.correo"
                  />
                  <MensajeValidacion :mensaje="errors.correo"/>
                </fieldset>
              </div>

              <div class="col-lg-6">
                <fieldset>
                  <Field
                      name="celular"
                      placeholder="Celular"
                      disabled
                      v-model.trim="formData.celular"
                  />
                  <MensajeValidacion :mensaje="errors.celular"/>
                </fieldset>
              </div>

              <div class="col-lg-12 " style="font-size: 12px; color: white;">
                <div class="row">
                  <div class="col-md-1 col-sm-1">
                    <input
                        type="checkbox"
                        name="terminos"
                        id="terminos"
                        class="check-politicas"
                        required
                        v-model.trim="formData.terminos"
                    />
                  </div>
                  <div class="col-md-11 col-sm-11">
                    Declaro haber leído, entendido y aceptado los <router-link class="form-check-label check-label" :to="{ name: 'Terminos' }" style="margin-left: 0px; font-weight: bolder">Términos y Condiciones
                  </router-link> y la Política de Privacidad de Datos de BEES
                  </div>
                </div>
              </div>
              <Alerta :alerta="alerta" v-if="alerta"/>
              <div>
                <div class="d-grid gap-2">
                  <button type="submit" class="boton-aceptar">
                    Registrar Solicitud
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {Form, Field} from "vee-validate";
import Alerta from "../../../components/Alerta";
import MensajeValidacion from "../../../components/MensajeValidacion";

export default {
  name: "ClienteForm",
  components: {
    Form,
    Field,
    Alerta,
    MensajeValidacion
  },
  data() {
    const formValidation = {
      nombreComercial: "required:Nombre comercial",
      nombre: "required:Nombre",
      correo: "required:Email|email"
    };
    return {
      formData: {
        empresa: "",
        nombreComercial: "",
        nombre: "",
        correo: "",
        celular: "",
      },
      formDataCopy: {},
      codigoCliente: "",
      cedula: "",
      formValidation,
      alerta: null
    };
  },
  mounted() {
    this.formDataCopy = {...this.formData};

    let cliente = this.$store.getters["cliente/cliente"];
    if (cliente) {
      this.formData.nombreComercial = cliente.nombreComercial;
      this.formData.nombre = cliente.nombre;
      this.formData.correo = cliente.correo;
      this.formData.celular = cliente.celular;
      this.codigoCliente = cliente.codigoCliente;
      this.cedula = cliente.cedula;
    }
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async submit() {
      this.alerta = null;

      let terminos = this.formData.terminos;

      if (!terminos) {
        this.alerta = "Debe aceptar los términos y condiciones";
        return false;
      }
      try {
        let solicitud = {
          codigoCliente: this.codigoCliente,
          celular: this.formData.celular,
          correo: this.formData.correo,
          nombre: this.formData.nombre,
          cedula: this.cedula
        };
        this.loaderSpinner();
        await this.$store.dispatch("registro/crear", solicitud);
        this.$router.push({name: "RegistroExitoso"});
      } catch (error) {
        (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0)
            ? (error.response.data.errors[0].includes("Read timed out")
                ? this.alerta = "Time out, no se recibe respuesta del servicio y no se pudo registrar la solicitud"
                : this.alerta = error.response.data.errors[0])
            : this.alerta = "No se pudo registrar la solicitud, por favor intente nuevamente"
      } finally {
        this.loader.hide();
      }

    },
  },
};
</script>
<style scoped>
.boton-aceptar {
  margin-right: 10px;
  margin-top: 10px;
}

.check-politicas {
  width: 20px !important;
  height: 20px !important;
}

.check-label {
  margin-left: 10px;
  color: white;
  padding-bottom: 5px;
}

</style>
