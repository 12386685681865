<template>
  <RegistroForm/>
</template>

<script>
import RegistroForm from "../cliente/components/RegistroForm";
export default {
  name: "Registro",
  components: {RegistroForm },
};
</script>