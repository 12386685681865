<template>
  <InventarioForm/>
</template>

<script>
import InventarioForm from "../inventario/components/InventarioForm";
export default {
  name: "Inventario",
  components: {InventarioForm },
};
</script>