<template>
  <div>
    <Mensaje mensaje="Se ha ingresado su solicitud. Se enviará la clave de acceso al número celular registrado"
             ruta="Home"/>
  </div>
</template>

<script>
import Mensaje from "../../components/Mensaje";
export default {
  components: {Mensaje}
};
</script>
