<template>
  <div>
    <!-- ***** Header Area Start ***** -->
    <header class="header-area header-sticky wow slideInDown" data-wow-duration="0.75s" data-wow-delay="0s">
      <div class="container">

        <div class="row " >
          <div class="col-12">
            <nav class="main-nav">

              <a href="#" class="logo">
                <h4>Servicios<span>Bees</span></h4>
              </a>

              <ul class="nav" :style="{'display':display ? 'none': 'block'}">

                <li class="scroll-to-section" v-if="!isLogged">
                  <router-link :to="{ name: 'Home' }" >
                    Inicio
                  </router-link>
                </li>
                <li class="scroll-to-section" v-if="!isLogged">
                  <router-link :to="{ name: 'Terminos' }" >
                    Términos y condiciones
                  </router-link>
                </li>

                <li class="scroll-to-section" v-if="isLogged">
                  <router-link :to="{ name: 'CuentaUsuario' }" >
                    Mi cuenta
                  </router-link>
                </li>
                <li class="scroll-to-section" v-if="isLogged && datosUsuario.rol==='ROLE_USER' && aprobado">
                  <router-link :to="{ name: 'Opciones' }" >
                    Mis servicios
                  </router-link>
                </li>
                <!--<li class="scroll-to-section" v-if="isLogged && datosUsuario.rol==='ROLE_USER' && aprobado">
                  <router-link :to="{ name: 'TransaccionesAfiliado' }" >
                    Movimientos
                  </router-link>
                </li>-->
                <li class="scroll-to-section" v-if="isLogged && datosUsuario.rol==='ROLE_ADMIN'">
                  <router-link :to="{ name: 'SolicitudesAfiliacion' }" >
                    Solicitudes
                  </router-link>
                </li>

                <li class="scroll-to-section" v-if="isLogged && datosUsuario.rol==='ROLE_ADMIN'">
                  <router-link :to="{ name: 'CargarFacturas' }" >
                    Facturas
                  </router-link>
                </li>

                <li class="scroll-to-section">
                  <div class="main-red-button">
                    <a href="javascript:void(0)" @click="salir" v-if="datosUsuario.id>0">Cerrar Sesión</a>
                  </div>
                </li>
              </ul>
              <a class='menu-trigger'  :class="{ active: isActive}" @click="openMobileNav">
                <span>Menu</span>
              </a>
            </nav>
          </div>
        </div>

      </div>
    </header>
  </div>
</template>
<script>

export default {
  name: "Navbar",
  data (){
    return   {
      display : true,
      isActive : false
    }

  },
  components: {},
  methods:{
    async salir(){
      try {
        await this.$store.dispatch("auth/logout");
        this.$router.push({ name: "Home" });
      } catch (error) {
        console.log(error)
      }
    },


    openMobileNav() {
      this.display = !this.display
      this.isActive = !this.isActive
    }

  },
  computed: {
    datosUsuario() {
      return this.$store.getters["auth/user"] ? this.$store.getters["auth/user"] :  [];
    },
    isLogged() {
      return this.$store.getters["auth/isLogged"] ? this.$store.getters["auth/isLogged"] : false;
    },
    aprobado(){
      return this.$store.getters["auth/user"] ? this.$store.getters["auth/user"].aprobado : false;
    }
  }
}
</script>
<style scoped>
.mobile{
  overflow: auto !important;
}
</style>
