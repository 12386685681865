<template>
  <div id="services" class="our-services">
    <div class="container">
      <div class="row">
        <div
            class="col-lg-6 align-self-center wow fadeInLeft"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
        >
          <div class="left-image">
            <img src="../../assets/images/verificacion.png" alt=""/>
          </div>
        </div>
        <div
            class="col-lg-6 wow fadeInRight"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
        >
          <div class="section-heading">
            <h2>¿Tienes disponible un celular de <span>Claro</span>?</h2>
          </div>

          <p>
            Si cuentas con un numero CLARO puedes acceder a este servicio
          </p>

          <br/>

          <div class="d-flex justify-content-start">
            <div class="main-blue-button">
              <router-link :to="{ name: 'Solicitud' }" class="boton-confirmacion"> Si</router-link>
            </div>
            <div class="main-red-button">
              <router-link :to="{ name: 'Mensaje' }" class="boton-confirmacion"> No</router-link>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Verificacion",
  methods: {
    navegarSi() {
      this.$router.push({name: "Solicitud"});
    },
    navegarNo() {
    },
  },
};
</script>

<style scoped>
.boton-confirmacion {
  width: 100px;
  text-align: center
}
</style>
