<template>
  <div>
    <Mensaje mensaje="Recarga exitosa" ruta="Opciones"/>
    <br/>
    <div class="d-flex justify-content-center">
      <Ticket :trx="datosTicket.trx" :fecha="datosTicket.fecha" :producto="datosTicket.producto" :numero="datosTicket.numero" :valor="datosTicket.valor"/>
    </div>

  </div>
</template>

<script>
import Mensaje from "../../components/Mensaje";
import Ticket from "../../components/Ticket";
export default {
  components: {Ticket, Mensaje},
  computed:{
    datosTicket(){
      return this.$store.getters["servicios/detalleRecarga"] ? this.$store.getters["servicios/detalleRecarga"] : []
    }
  }
};
</script>
