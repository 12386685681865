<template>
  <div id="portfolio" class="our-portfolio section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 offset-lg-3">
          <div
              class="section-heading wow bounceIn"
              data-wow-duration="1s"
              data-wow-delay="0.2s">
            <h2>Política de Privacidad</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
