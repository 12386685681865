<template>
  <div id="blog" class="our-blog section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 offset-lg-3">
          <div class="section-heading text-center  wow bounceIn" data-wow-duration="1s" data-wow-delay="0.2s">
            <h2>Bienvenido</h2>
            <h2><em>Servicios Bees </em></h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.opciones {
  border: 1px solid #ED3123;
  width: 250px !important;
}

</style>
