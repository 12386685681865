<template>
  <div>
    <h6 class="texto mensaje"><i class="fa fa-warning"></i> {{ alerta }}</h6>
  </div>
</template>
<script>
export default {
  props: ['alerta']
}
</script>
<style scoped>
.texto {
  color: white;
  text-align: center;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: normal;
}

.mensaje {
  background-color: gray;
  padding: 10px;
  border-radius: 20px;
  opacity: 0.8;
}
</style>
