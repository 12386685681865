<!--<template>

  <div id="portfolio" class="our-portfolio section">

    <div class="container" v-if="isLogged && aprobado">
      <div class="row">
        <div class="col-lg-6 offset-lg-3">
          <div class="section-heading  wow bounceIn" data-wow-duration="1s" data-wow-delay="0.2s">
            <h2>Servicios digitales <em> disponibles </em></h2>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <div class="row">
          <router-link :to="{ name: 'RecargaClaro' }">
            <div class=" wow bounceInUp " data-wow-duration="1s" data-wow-delay="0.3s">
              <div class="showed-content opciones">
                <div class="row justify-content-center">
                  <img src="../../assets/images/claro-recarga.png" alt="">
                </div>

              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <router-link :to="{ name: 'Inventario' }">
          <div class=" wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.4s">
            <div class="showed-content opciones">
              <img src="../../assets/images/portfolio-image.png" alt="">
            </div>
          </div>
        </router-link>
      </div>
      <div class="d-flex justify-content-center">
        <a href="#">
          <div class=" wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.5s">

            <div class="showed-content opciones">
              <img src="../../assets/images/portfolio-image.png" alt="">
            </div>
          </div>
        </a>
      </div>

    </div>
    <div class="container" v-else>
      <div class="row">
        <div class="col-lg-6 offset-lg-3">
          <div class="section-heading  wow bounceIn" data-wow-duration="1s" data-wow-delay="0.2s">
            <h2>Estimado usuario</h2>
            <h2><em>su solicitud se encuentra pendiente de aprobación </em></h2>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>-->


<template>
  <div id="blog" class="our-blog section">

    <div class="container" v-if="isLogged && aprobado">

      <div class="section-heading text-center">
        <h2>Servicios digitales <em> disponibles </em></h2>
      </div>
      <div class="row">
        <div class="col-lg-6 offset-lg-3 col-md-12 ">

          <div class="right-list">
            <ul>
              <li>
                <div class="left-content align-self-center">

                  <h4>Valide su Factura de Recargas</h4>
                  <p>Servicio para visualización de las facturas</p>
                </div>
                <div class="right-image">
                  <router-link :to="{ name: 'ValideFactura' }">
                    <img class="opciones" src="../../assets/images/claro-facturas.fw.png" alt="">
                  </router-link>
                </div>
              </li>
              <li>
                <div class="left-content align-self-center">

                 <h4>Recargas</h4>
                  <p>Servicio de recargas digitales de pines y datos para números claro</p>
                </div>
                <div class="right-image">

                  <router-link :to="{ name: 'RecargaClaro' }">
                    <img class="opciones" src="../../assets/images/claro-recarga.png" alt="">
                  </router-link>

                </div>
              </li>
              <li>
                <div class="left-content align-self-center">

                  <h4>Consulta de Inventario</h4>
                  <p>Consulta de su saldo didsponible para usar en recargas a números claro</p>
                </div>
                <div class="right-image">
                  <router-link :to="{ name: 'Inventario' }">
                    <img class="opciones" src="../../assets/images/claro-inventario.fw.png" alt="">
                  </router-link>
                </div>
              </li>

              <li>
                <div class="left-content align-self-center">

                  <h4>Movimientos</h4>
                  <p>Consulta las recargas realizadas a Claro realizadas</p>
                </div>
                <div class="right-image">
                  <router-link :to="{ name: 'TransaccionesAfiliado' }">
                    <img class="opciones" src="../../assets/images/claro-movimientos.fw.png" alt="">
                  </router-link>
                </div>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="container" v-else>
      <div class="row">
        <div class="col-lg-6 offset-lg-3">
          <div class="section-heading text-center" data-wow-duration="1s" data-wow-delay="0.2s">
            <h2>Estimado usuario</h2>
            <h2><em>En 24 horas estarás habilitado para realizar tu primera compra de RECARGAS CLARO en
              BEES y ¡empezar a vender!</em></h2>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>


<style scoped>

.opciones {
  border: 1px solid #ED3123;
  width: 250px !important;
}

</style>
<script>

export default {
  name: "OpcionesPrincipales",
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$router.push({name: "Home"});
      return false;
    }
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"] ? this.$store.getters["auth/isLogged"] : false;
    },
    aprobado() {
      return this.$store.getters["auth/user"] ? this.$store.getters["auth/user"].aprobado : false;
    }
  }
}
</script>
