<template>

  <div class="our-services section">

    <div class="container">
      <div class="row">
        <div class="col-lg-6 offset-lg-3 col-md-6 col-sm-12">

          <div class="card">
            <div class="card-body">
              <div class="section-heading">
                <h3>Mi cuenta</h3>
              </div>
              <br/>
              <form>
                <div class="row">
                  <dt class="col-sm-3">Código cliente:</dt>
                  <dd class="col-sm-9">{{ codigoCliente }}</dd>
                  <dt class="col-sm-3">Nombre:</dt>
                  <dd class="col-sm-9">{{ nombre }}</dd>
                  <dt class="col-sm-3">Email:</dt>
                  <dd class="col-sm-9">{{ correo }}</dd>
                  <dt class="col-sm-3">Celular:</dt>
                  <dd class="col-sm-9">{{ celular }}</dd>
                </div>
                <br/>
                <Alerta :alerta="alerta" v-if="alerta"/>
              </form>
              <br/>
              <a type="button" @click="cambiarCelular" :class="datosUsuario.aprobado ? 'enabled-link' : 'disabled-link'">
                Cambiar celular
              </a>

              <Form @submit="submitCambioCelular" id="contact" class="formulario"
                    :validation-schema="formValidation" v-slot="{errors}" v-if="mostrarCambiarCelular">
                <div class="row">
                  <div class="col-lg-12">
                    <fieldset>
                      <Field
                          type="text"
                          name="nuevoCelular"
                          v-model.trim="nuevoCelular"
                          placeholder="Ingrese su nuevo número de celular"                      />
                      <MensajeValidacion :mensaje="errors.nuevoCelular" class="colorValidacion"/>
                    </fieldset>
                  </div>

                  <div>
                    <div class="d-grid gap-2">
                      <button type="button" @click="submitCambioCelular" class=" boton-aceptar">
                        Cambiar celular
                      </button>
                    </div>
                  </div>
                </div>
              </Form>

            </div>
          </div>
          <br/>
          <div class="card">
            <div class="card-body">

              <div>
                <div class="accordion-item">
                  <div class="section-heading">
                    <h3>Seguridad</h3>
                  </div>
                  <br/>
                  <a type="button" @click="cambiarContrasenia">
                    Cambiar clave
                  </a>
                  <Form @submit ="submit" id="contact" class="formulario"
                        :validation-schema="formValidation" v-slot="{errors}" v-if="mostrarCambiarContrasenia">
                    <div class="row">
                      <div class="col-lg-12">
                        <fieldset>
                          <Field
                              type="password"
                              name="clave"
                              placeholder="Ingrese la nueva clave"
                              v-model.trim="formData.clave"
                          />
                          <MensajeValidacion :mensaje="errors.clave" class="colorValidacion"/>
                        </fieldset>
                      </div>
                      <div class="col-lg-12">
                        <fieldset>
                          <Field
                              type="password"
                              name="confirmaClave"
                              placeholder="Confirme la nueva clave"
                              v-model.trim="formData.confirmaClave"
                          />
                          <MensajeValidacion :mensaje="errors.confirmaClave" class="colorValidacion"/>
                        </fieldset>
                      </div>
                      <div>
                        <div class="d-grid gap-2">
                          <button type="submit" class=" boton-aceptar">
                            Cambiar clave
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Form, Field} from "vee-validate";
import Alerta from "../../../components/Alerta";
import MensajeValidacion from "../../../components/MensajeValidacion";

export default {
  name: "ClienteForm",
  components: {
    Form,
    Field,
    Alerta,
    MensajeValidacion
  },
  data() {
    const formValidation = {
      clave: "required:clave",
      confirmaClave: "required:confirmar clave|confirmed:@clave,las claves no coinciden"

    };
    return {
      nombre: "",
      correo: "",
      codigoCliente: "",
      celular : "",
      alerta: null,
      formValidation,
      formData: {
        clave: null,
        confirmaClave: null
      },
      nuevoCelular: "",
      formDataCopy: {},
      mostrarCambiarContrasenia: true,
      mostrarCambiarCelular : false,
      clave: "5566",
      usuario: "PRUEBAREC"
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$router.push({name: "Home"});
      return false;
    }
  },
  mounted() {
    this.formDataCopy = {...this.formData};
    let user = this.$store.getters["auth/user"];
    if (user) {
      this.nombre = user.nombre;
      this.correo = user.correo;
      this.codigoCliente = user.codigoCliente;
      this.celular = user.celular
    }
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async submit() {
      this.alerta = null;
      this.loaderSpinner();
      try {
        console.log('entra')
        await this.$store.dispatch("auth/cambiarClave", {claveNueva: this.formData.clave});
        await this.$store.dispatch("auth/logout");
        this.$router.push({name: "MensajeExitoso", params:{mensaje: "Se ha cambiado la clave con éxito"}});
      } catch (error) {
        error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0 ? this.alerta = error.response.data.errors[0] : this.alerta = "No se pudo cambiar la clave"
      } finally {
        this.loader.hide();
      }
    },
    async submitCambioCelular() {
      this.alerta = null;
      this.loaderSpinner();
      try {
        await this.$store.dispatch("auth/cambiarNumeroCelular", {celularNuevo: this.nuevoCelular});
        await this.$store.dispatch("auth/logout");
        this.$router.push({name: "MensajeExitoso", params:{mensaje: "Se ha cambiado el número celular con éxito"}});
      } catch (error) {
        error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0 ? this.alerta = error.response.data.errors[0] : this.alerta = "No se pudo cambiar el número celular"
      } finally {
        this.loader.hide();
      }
    },
    cambiarContrasenia(){
      this.mostrarCambiarContrasenia = !this.mostrarCambiarContrasenia;
    },
    async cambiarCelular(){
      this.mostrarCambiarCelular = false
      this.alerta = null;
      //Consultar inventario
      this.loaderSpinner();
      try {
        let inventario = await this.$store.dispatch("servicios/consultarInventario", {
          clave: this.clave,
          usuario: this.usuario
        });
        if (inventario && inventario.estado === "SUCCESS") {
          this.alerta = "Estimado cliente tiene inventario disponible, no se puede realizar el cambio";
        } else {
          this.alerta = "No se pudo validar la información";
        }
      } catch (error) {
        (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0 && error.response.data.errors[0].includes("No existe stock"))
        ?  this.mostrarCambiarCelular = true : null
      } finally {
        this.loader.hide();
      }

    }
  },
  computed:{
    datosUsuario() {
      return this.$store.getters["auth/user"] ? this.$store.getters["auth/user"] : [] ;
    }
  }
};
</script>
<style scoped>
.boton-aceptar {
  margin-right: 10px
}
.formulario {
  background: #f3f3f3 !important;
}
.colorValidacion{
    color:black;
}
.disabled-link {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
  color: black;
}
.enabled-link {
  color: black;
}
</style>
