<template>
   <Navbar/>
   <router-view/>
</template>
<script>
import Navbar from "./components/Navbar";
export default {
  components: {Navbar},
}
</script>

<style>
@import "assets/css/bootstrap.min.css";
@import "assets/css/fontawesome.css";
@import "assets/css/templatemo-space-dynamic.css";
@import "assets/css/animated.css";
@import "assets/css/owl.css";
</style>
