<template>
  <div>
    <!--<Navbar/>-->
    <div
        class="main-banner wow fadeIn"
        id="top"
        data-wow-duration="1s"
        data-wow-delay="0.5s"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-6 mycontent-left">
                <div
                    class="left-content header-text wow fadeInLeft"
                    data-wow-duration="1s"
                    data-wow-delay="1s"
                >
                  <h6>Si quieres vender recargas</h6>
                  <h2>
                    Solo para teléfonos <span>Claro</span>
                  </h2>
                  <p>
                    Ya tienes una cuenta puedes ingresar en el fomulario de  Inicio de Sesión

                  </p>

                  <div class="d-grid gap-2">
                    <div class="main-blue-button">
                      <router-link :to="{ name: 'Verificacion' }">
                        Registrate aquí
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 ">
                <div
                    class="right-image wow fadeInRight"
                    data-wow-duration="1s"
                    data-wow-delay="0.5s"
                >
                  <!--<img
                      src="../assets/images/banner-right-image.png"
                      alt="team meeting"
                  />-->

                  <LoginForm class="margen-login"/>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Footer from "./Footer";
import LoginForm from "../views/login/components/LoginForm";

export default {
  name: "HelloWorld",
  components: {LoginForm, Footer},
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mycontent-left {
  border-right: 2px solid gray;
}
.margen-login{
  margin-left: 40px;
}
</style>
