<template>
  <div class="our-services section">

    <div class="container">
      <div class="row">
        <div
            class="col-lg-6 align-self-center wow fadeInLeft"
            data-wow-duration="0.5s"
            data-wow-delay="0.25s"
        >
          <div class="left-image">
            <img src="../../../assets/images/solicitud.png" alt=""/>
          </div>
        </div>

        <div
            class="col-lg-6 wow fadeInRight"
            data-wow-duration="0.5s"
            data-wow-delay="0.25s"
        >

          <div class="section-heading">
            <h2>Servicio disponible solo para clientes <span>Claro</span></h2>
          </div>

          <p>
            Si cuentas con un numero CLARO puedes acceder a este servicio
          </p>

          <Form @submit="submit" id="contact" :validation-schema="formValidation" v-slot="{errors}">
            <div class="row">
              <div class="col-lg-12">
                <fieldset>
                  <Field
                    name="codigoCliente"
                    placeholder="Código de cliente"
                    v-model.trim="formData.codigoCliente"
                  />
                  <MensajeValidacion :mensaje="errors.codigoCliente"/>
                </fieldset>
              </div>
              <div class="col-lg-12">
                <fieldset>
                  <Field
                    name="cedula"
                    placeholder="Cédula"
                    v-model.trim="formData.cedula"
                  />
                  <MensajeValidacion :mensaje="errors.cedula"/>
                </fieldset>
              </div>
              <div class="col-lg-12">
                <fieldset>
                  <Field
                    name="celular"
                    placeholder="Número Claro para recibir las recargas"
                    v-model.trim="formData.celular"
                  />
                  <MensajeValidacion :mensaje="errors.celular"/>
                </fieldset>
              </div>
              <Alerta :alerta="alerta" v-if="alerta"/>
              <div>

                <div class="d-grid gap-2">
                  <button type="submit" class=" boton-aceptar">
                    Validar
                  </button>
                </div>

              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field } from "vee-validate";
import Alerta from "../../../components/Alerta";
import MensajeValidacion from "../../../components/MensajeValidacion";
export default {
  name: "SolicitudForm",
  components: {
    Form,
    Field,
    Alerta,
    MensajeValidacion
  },
  data(){
    const formValidation = {
      codigoCliente: "required:Código de cliente",
      cedula: "required:Cédula",
      celular: "required:Número Claro para recibir las recargas"
    };
    return{
      formData:{
        codigoCliente: "",
        cedula: "",
        celular: ""
      },
      formDataCopy: {},
      formValidation,
      alerta: null
    };
  },
  mounted(){
    this.formDataCopy = { ...this.formData };
  },
  methods: {
    async submit() {
      this.alerta = null;
      try {
        //Consulta si existe el cliente
        let datosCliente = await this.$store.dispatch("cliente/consultar", {
          codigoCliente: this.formData.codigoCliente,
          cedula: this.formData.cedula,
          celular: this.formData.celular
        });
        if(datosCliente){
          this.$router.push({name: "Registro"});
        }else{
          console.log("Sin datos de cliente");
        }
      } catch (error) {
        error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0 ? this.alerta = error.response.data.errors[0] : this.alerta = "No se pudo validar la información"
      }

    },
  },
};
</script>
<style scoped>
.boton-aceptar{
  margin-right: 10px
}
</style>
