<template>
  <div id="portfolio" class="our-portfolio section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 offset-lg-3">
          <div class="section-heading  wow bounceIn" data-wow-duration="1s" data-wow-delay="0.2s">
            <h2>Inventario</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 offset-lg-3">
          <div
              class="section-heading wow bounceIn"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
              v-if="Array.isArray(inventario)">

            <!-- <h4 class="texto-centrado" v-for="(item,index) in inventario" :key="index">{{ item }}</h4> -->

            <table class="table">
              <thead>
              <tr>
                <th scope="col">Producto</th>
                <th scope="col">Saldo</th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in inventario" :key="index">
                  <td>{{ item.producto }}</td>
                  <td>$ {{ item.saldo }}</td>
                </tr>
              </tbody>
            </table>

          </div>
          <div
              class="section-heading wow bounceIn"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
              v-else>
            <h4 class="texto-centrado">{{ inventario }}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {split} from "lodash";

export default {
  name: "Inventario",
  data() {
    return {
      inventario: null,
      clave: "5566",
      usuario: "PRUEBAREC"
    }
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$router.push({name: "Home"});
      return false;
    }
  },
  mounted() {
    this.consultarInventario();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarInventario() {
      this.inventario = null;
      this.loaderSpinner();
      try {
        let inventario = await this.$store.dispatch("servicios/consultarInventario", {
          clave: this.clave,
          usuario: this.usuario
        });
        if (inventario && inventario.estado === "SUCCESS") {
          let inventarioTemp = inventario ? split(inventario.mensaje, ";") : null;
          let inventarioArray = [];
          let elementoArray = [];
          inventarioTemp && inventarioTemp.length > 0 && inventarioTemp.map(item => {
            if(item.length > 0){
              elementoArray = item && split(item, "-");
              inventarioArray.push({producto: elementoArray[0]  === 'Pines PVIR MOVIL POS ' ? 'TU SALDO DISPONIBLE' : 'OTRO SALDO (SOLO PRUEBAS)', saldo: elementoArray[1]});
            }
          })
          this.inventario = inventarioArray;
        } else {
          this.inventario = "No se pudo recuperar la información"
        }
      } catch (error) {
        (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) 
        ? (error.response.data.errors[0].includes("Read timed out") 
          ? this.inventario = "Time out, no se recibe respuesta del servicio y no se pudo recuperar el inventario" 
          : this.inventario = error.response.data.errors[0]) 
        : this.inventario = "No se pudo recuperar la información del inventario";
      } finally {
        this.loader.hide();
      }
    }
  }
}
</script>
<style>
.texto-centrado {
  text-align: center;
}
</style>
