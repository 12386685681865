<template>
  <div>
    <!--<Navbar/>-->

    <div id="services" class="our-services">

      <div class="container" v-if="isLogged">
        <div class="row">
          <div
              class="col-lg-6 align-self-center wow fadeInLeft"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
          >
            <div class="left-image">
              <img src="../../assets/images/services-left-image.png" alt=""/>
            </div>
          </div>
          <div
              class="col-lg-6 wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
          >
            <div class="section-heading">
              <h2>Facturas <span>Claro</span></h2>
            </div>

            <p>
              Cargar Estado de Facturas
            </p>

            <br/>
            <input 
              type="file" 
              id="file"
              @change="onFileChange"
              accept=".csv" 
              class="form-control" 
              multiple 
            /> 
            <br/>
            <div class="row" v-if="mensaje">
              <div class="col-lg-12">
                <div
                    class="section-heading wow bounceIn"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s">
                  <h5>{{ mensaje }}</h5>
                </div>
              </div>
            </div>
            <br/>
            <td class="main-blue-button" v-if="mostrarBotonGuardar"><a href="#" @click="cargarArchivo" class="boton-confirmacion" >Cargar</a></td>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CargarFacturas",
  data(){
    return {
      mensaje: null,
      mostrarBotonGuardar: false,
      file: null
    }
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$router.push({name: "Home"});
      return false;
    }
  },
  methods:{
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    onFileChange(e) {
      this.mensaje = null;
      this.mostrarBotonGuardar = false;
      var files = e.target.files || e.dataTransfer.files; 
      if (!files.length) return; 
      this.createFile(files[0]); 
    }, 
    createFile(file) { 
      let allowedExtensions = /(.xls|.xlsx|.csv)$/i; 
      if (!allowedExtensions.exec(file.name)) {
        this.mensaje = "Formato no permitido";
        return false; 
      } 
      var reader = new FileReader(); 
      var vm = this; 
 
      reader.onload = (e) => { 
        vm.image = e.target.result;
      }; 
      reader.readAsDataURL(file); 
      this.file = file;
      this.mostrarBotonGuardar = true;
    },
    async cargarArchivo() {
      this.mensaje = null;
      this.loaderSpinner();
      try { 
        let formData = new FormData(); 
        formData.append("file", this.file); 
        let config = { 
          headers: { 
            "content-type": "multipart/form-data", 
          }, 
        };
        let data = { formData, config }; 
        let mensaje = await this.$store.dispatch("factura/cargarArchivo", data);
        this.mensaje = mensaje;
        this.mostrarBotonGuardar = false;
      } catch (error) { 
        this.loader.hide(); 
        this.mensaje = "No se pudo cargar el archivo";
      } finally {
        this.loader.hide();
      }
    }
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"] ? this.$store.getters["auth/isLogged"] : false;
    },
  }
}
</script>
