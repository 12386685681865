<template>
  <div>
    <Mensaje :mensaje="mensaje" ruta="Home"/>
  </div>
</template>

<script>
import Mensaje from "../../components/Mensaje";
export default {
  components: {Mensaje},
  computed:{
    mensaje(){
      return this.$route.params ? this.$route.params.mensaje : "";
    }
  }
};
</script>