import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import { defineRule } from "vee-validate";
import { email, min, integer, min_value } from "@vee-validate/rules";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

defineRule("email", email);
defineRule("min", min);
defineRule("integer", integer);
defineRule("min_value", min_value);

defineRule("required", (value, [msg]) => {
  if(!value || !value.length){
    return msg + " es requerido";
  }
  return true;
});

defineRule("length", (value, [msg,tamanio]) => {
  if(value.length!==parseInt(tamanio,10)){
    return msg;
  }
  return true;
});

defineRule("confirmed", (value1, [value2,msg]) => {
  if(value1 !== value2){
    return msg;
  }
  return true;
});

defineRule("numeric", (value1, [msg]) => {
  console.log(value1)
  if(parseInt(value1,10) <= 0 || Number(value1)===parseInt(value1,10)){
    return msg;
  }
  return true;
});

axios.defaults.baseURL = "https://www.serviciosbees.com/app/";
//axios.defaults.baseURL = "http://localhost:8080/";

//interceptors
axios.interceptors.request.use(
  config => {
    const token = store.getters["auth/token"];
    config.headers.Authorization = `Bearer ${token}`;

    /* if (token != null) {
      if (jwt_decode(token).exp >= Date.now().valueOf() / 1000) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        //eliminar el token y data de sesión
        store.dispatch("auth/logout");

        //salir a login
        router.replace("/");
      }
    }else{
      //eliminar el token y data de sesión
      store.dispatch("auth/logout");

      //salir a login
      router.replace("/");
    } */
    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);

createApp(App).use(Loading, {
  canCancel: false,
  loader: "spinner",
  color: "#ED3123"
}).use(store).use(router).mount('#app')
