<template>

      <div class="row">
        <div
            class="col-lg-12 col-md-12  wow fadeInRight"
            data-wow-duration="0.5s"
            data-wow-delay="0.25s"
        >

          <div class="section-heading">
            <h2>Inicio de <em>sesión</em></h2>
          </div>
          <div>
            <p>
              Si tienes una cuenta inicia sesión
            </p>
          </div>

          <Form @submit="submit" id="contact" :validation-schema="schema" v-slot="{errors}">
            <div class="row">
              <div class="col-lg-12">
                <fieldset>
                  <Field
                    name="codigoCliente"
                    placeholder="Código de cliente"
                    v-model.trim="formData.codigoCliente"
                  />
                  <div class="error">
                    <small>{{ errors.codigoCliente }}</small>
                  </div>

                </fieldset>
              </div>

              <div class="col-lg-12">
                <fieldset>
                  <Field
                    type="password"
                    name="clave"
                    placeholder="Clave"
                    v-model.trim="formData.clave"
                  />
                  <div class="error">
                    <small>{{ errors.clave }}</small>
                  </div>

                </fieldset>
              </div>
              <Alerta :alerta="alerta" v-if="alerta"/>
              <div v-if="procesando">
                <div class="d-grid gap-2">
                  <SpinnerButton/>
                </div>
              </div>
              <div v-else>
                <div class="d-grid gap-2">
                  <button type="submit" class="boton-aceptar">
                    Ingresar
                  </button>
                </div>
              </div>
              <div class="div">
                <router-link :to="{ name: 'RecuperarClave' }" class="texto">
                  Recuperar clave
                </router-link>
              </div>
            </div>
          </Form>
        </div>
      </div>

</template>
<script>
import { Form, Field } from "vee-validate";
import Alerta from "../../../components/Alerta";
import SpinnerButton from "../../../components/SpinnerButton";
export default {
  name: "LoginForm",
  components: {
    Form,
    Field,
    Alerta,
    SpinnerButton
  },
  data(){
    const schema = {
      codigoCliente: "required:Código de cliente",
      clave: "required:Clave"
    };
    return{
      formData:{
        codigoCliente: "",
        clave: ""
      },
      schema,
      alerta: null,
      procesando: false
    };
  },
  methods : {
    isRequired(value) {
      if (value && value.trim()) {
        return true;
      }
      return 'El campo es requerido';
    },
    async submit() {
      this.alerta = null;
      this.procesando = true;
      try {
        await this.$store.dispatch("auth/login", {
          codigoCliente: this.formData.codigoCliente,
          clave: this.formData.clave
        });
        let user = await this.$store.dispatch("auth/consultarDatosUsuario");
        if(user && user.rol === "ROLE_USER"){
          this.$router.push({name: "Opciones"});
        }
        if(user && user.rol === "ROLE_ADMIN"){
          this.$router.push({name: "InicioAdmin"});
        }
      } catch (error) {
        if(error.response && error.response.status === 401){
          this.alerta = "No se pudo validar la información, por favor revise las credenciales de acceso";
          return false;
        }
        //error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0 ? this.alerta = error.response.data.errors[0] : this.alerta = "No se pudo validar la información"
        this.alerta = "No se pudo validar la información";
      }finally{
        this.procesando = false;
      }

    },
  }
}
</script>
<style>
.error{
  color: #F5FC0A;
  font-weight: normal !important;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: -20px;
  margin-left: 10px;
}
.texto{
  color: white;
  font-size: 14px;
}
.div{
  text-align: center;
  padding-top: 10px;
}
</style>
