import axios from "axios";

const URL = "api/v1/upload";
const URL_1 = "api/v1/facturas";

const state = {
  mensaje: null,
  trackingFactura: []
};

const getters = {
  mensaje: state => state.mensaje,
  trackingFactura: state => state.trackingFactura
};

const mutations = {
  setMensaje: (state, mensaje) => {
    state.mensaje = mensaje;
  },
  setTrackingFactura: (state, trackingFactura) => {
    state.trackingFactura = trackingFactura;
  }
};

const actions = {
  cargarArchivo: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL}`, data.formData, data.config)
        .then(res => {
          commit("setMensaje",res.data.result);
          resolve(res.data.result);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  consultarFactura: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_1}/${data.numeroPedido}`)
        .then(res => {        
          commit("setTrackingFactura", res.data.result);
          resolve(res.data.result);
        })
        .catch(error => reject(error));
    });
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
