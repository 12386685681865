<template>
  <div id="portfolio" class="our-portfolio section">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 offset-lg-1">
          <div
              class="section-heading "
             >
            <h2>Términos y Condiciones</h2>
            <p align="justify">
              Los presentes TÉRMINOS Y CONDICIONES (en adelante “Términos y Condiciones”) regulan la relación contractual de carácter comercial entre los usuarios (en adelante “Usuarios” o “Clientes”), con DINADEC S.A. (en adelante “DN”). El servicio se encuentra dirigido exclusivamente para residentes de la República del Ecuador, mismos que pueden acceder al Sitio WEB (en adelante “Plataforma”, “Sitio Web” y/o “Sitio”) y crear una cuenta para administrar el inventario o cupo de recargas digitales adquirido en la plataforma BEES y gestionar la reventa de recargas digitales hacia al consumidor final.
            </p>
            <br/>
            <h5 align="justify">
              1. DEFINICIONES:
            </h5>
            <br/>
            <p align="justify">
              • Sitio Web o Plataforma: El Sitio Web es una herramienta tecnológica que, a través de internet, facilita la venta de productos dispuestos por el Establecimiento de Comercio para su comercialización y que son visualizados por el Usuario en la Plataforma, a través de la cual, los usuarios podrán seleccionarlos y realizar una orden de pedido u oferta de compra (en adelante “Oferta de compra” o “Pedido”) de estos, para adquirirlos en línea.
            </p>

            <br/>
            <p align="justify">
              • Usuario: establecimiento comercial cliente de DN que es titular de los productos, quien es identificado en el Sitio, y actúa como expendedor de los productos y servicios al consumidor final.
            </p>

            <br/>
            <p align="justify">
              • Territorio de cobertura: la zona y/o lugar de influencia en su respectiva localidad o municipio, a los que de acuerdo con sus medios de acceso pueda ofrecer la entrega de los productos el Establecimiento de Comercio.
            </p>
            <br/>
            <h5 align="justify">
              2. REGISTRO DE USUARIO:
            </h5>

            <br/>
            <p align="justify">
              • Para adquirir los productos de la Plataforma será necesario el registro del Usuario. Por ello, el Usuario deberá completar con datos válidos todos los campos del formulario de inscripción correspondiente. Para poder acceder al producto, el Usuario deberá verificar que la información que pone a disposición de DN es exacta, precisa y verdadera (en adelante “Datos Personales”). Así mismo, el Usuario asumirá el compromiso de actualizar sus Datos Personales cada vez que los mismos sufran modificaciones.
            </p>

            <br/>
            <p align="justify">
              • DN podrá usar diversos medios para identificar a los Usuarios, pero DN no se responsabiliza por la certeza de los Datos Personales registrados por los Usuarios. Los Usuarios garantizan y responden por la veracidad, exactitud, vigencia y autenticidad de los Datos Personales puestos a disposición de DN.
            </p>

            <br/>
            <p align="justify">
              • Para el uso de la plataforma y adquirir la calidad de Usuario, el potencial usuario deberá brindar su consentimiento para el tratamiento de Datos Personales, aceptar la Política de Privacidad y Tratamiento de Datos Personales, y los presentes Términos y Condiciones.
            </p>

            <br/>
            <p align="justify">
              • El Usuario podrá acceder a su cuenta personal con la contraseña elegida libremente durante el registro y con su nombre de usuario o correo electrónico. La cuenta es personal, única e intransferible. El Usuario será el único responsable por el uso de su cuenta.
            </p>

            <br/>
            <p align="justify">
              • Los Datos Personales que el Usuario proporcione se integrarán en una base de datos personales de la que es responsable DN. Para más información, consultar la Política de Privacidad y Tratamiento de Datos Personales.
            </p>

            <br/>
            <h5 align="justify">
              3. DECLARACIONES:
            </h5>

            <br/>
            <p align="justify">
              • Al registrarse en la Plataforma, el Usuario declara que:
            </p>

            <br/>
            <p align="justify">
              a) Es mayor de 18 años, tiene capacidad legal para contratar la adquisición de los bienes ofrecidos en el Sitio y hacer uso del mismo.
            </p>

            <br/>
            <p align="justify">
              c) Comprende que los nombres de usuario y contraseñas son personales y deben ser considerados confidenciales e intransferibles. En caso de que la sesión sea compartida con otro Usuario, se podrá restringir el acceso al Sitio Web. El Usuario no podrá autorizar a terceros a utilizar su cuenta, y en caso de hacerlo, lo hará bajo su exclusiva responsabilidad respondiendo por las actividades ejecutadas por el tercero como si fueran suyas.
            </p>

            <br/>
            <p align="justify">
              d) Entiende que los datos proporcionados, mediante el proceso de registro en el Sitio, como por ejemplo su nombre completo, su número de identificación, los datos que contiene su documento de identificación, su dirección física o de correo electrónico, su número de celular, la información relativa a las compras realizadas, y cualquier otra información requerida debe ser veraz y actualizada. El Usuario será el único responsable por los daños que se lleguen a causar por la información incorrecta, falsa o desactualizada que proporcione.
            </p>

            <br/>
            <p align="justify">
              e) Entiende y acepta que su solicitud de registro se puede rechazar, cancelar o suspender, temporal o definitivamente, en caso de que DN detecte incongruencias o inconsistencias en la información que ha sido suministrada
            </p>

            <br/>
            <p align="justify">
              f) Conoce que DN se reserva la posibilidad de bloquear a Usuarios en caso de detectar actividades sospechosas o inusuales, especialmente ante posibles fraudes, estafas, uso de datos ajenos. La decisión será debidamente comunicada al Usuario. Esta decisión no generará para el Usuario derechos de indemnización o resarcimiento.
            </p>

            <br/>
            <p align="justify">
              g) Conoce que el número de celular que registra en Servicios BEES es el correcto para la acreditación del cupo y gestión de venta de las recargas digitales de Claro. Por ende, reconoce expresamente que no cabrá la devolución, recuperación, transferencia o reembolso del cupo de recargas digitales o su equivalente en dinero, en caso de que el Usuario haya registrado el número de celular incorrecto, inválido o fuera de servicio.
            </p>

            <br/>
            <p align="justify">
              h)  Entiende que el Usuario es el único responsable del buen uso,  cuidado y mantenimiento de su cuenta en Servicios BEES, número de celular registrado y la gestión de venta al consumidor final. En consecuencia, libera a DINADEC S.A. de toda responsabilidad y renuncia a presentar reclamo o acción legal de cualquier naturaleza.
            </p>

            <br/>
            <p align="justify">
              h) El Usuario entiende que DN únicamente ha desarrollado esta plataforma en beneficio de los Usuarios y los consumidores finales, existiendo entre ellos la relación comercial principal y sin que DN tenga responsabilidad sobre ella.
            </p>

            <br/>
            <p align="justify">
              i) El Usuario entiende que la adquisición del cupo de recargas digitales se rigiéndose por los Términos y Condiciones  de la plataforma BEES previamente aceptados.
            </p>


            <br/>
            <h5 align="justify">
              4.	SOBRE LOS PRODUCTOS Y/O SERVICIOS
            </h5>

            <br/>
            <p align="justify">
              • El Usuario podrá encontrar en el Sitio Web un catálogo de productos ofrecidos por el Establecimiento de Comercio, quien es el titular de los productos. El fin de este catálogo es que el Usuario pueda dar aviso, mediante la Oferta de compra, que desea adquirir un producto que ofrece el Establecimiento de Comercio
            </p>

            <br/>
            <h5 align="justify">
              5.	INCIDENTES
            </h5>

            <br/>
            <p align="justify">
              Todas las peticiones o quejas que se presenten deberán ser reportadas a través de los canales de atención al cliente de la plataforma BEES.
            </p>

            <br/>
            <h5 align="justify">
              6.	USO Y GARANTÍA DE LA PLATAFORMA
            </h5>

            <br/>
            <p align="justify">
              • DN tendrá las facultades para denegar o restringir el uso de la Plataforma a cualquier Usuario en caso de incumplimiento de los presentes Términos y Condiciones, sin que ello genere perjuicio alguno al Usuario. El Usuario se compromete a hacer un uso adecuado y lícito de la Plataforma, de conformidad con la legislación aplicable, los presentes Términos y Condiciones, la moral y buenas costumbres generalmente aceptadas y el orden público.
            </p>

            <br/>
            <p align="justify">
              • Al utilizar el Sitio Web, el Usuario acuerda que:
            </p>

            <br/>
            <p align="justify">
              a. Solo utilizará el Sitio para su uso personal y no tendrá facultades para ceder, transferir o  revender su Cuenta a un tercero.
            </p>

            <br/>
            <p align="justify">
              b. No cederá ni transferirá de otro modo su Cuenta a ninguna otra persona o entidad legal.
            </p>

            <br/>
            <p align="justify">
              d. No tratará de dañar el Sitio Web de ningún modo, ni accederá a recursos restringidos en la Aplicación.
            </p>

            <br/>
            <p align="justify">
              e. Guardará de forma segura y confidencial la contraseña de su Cuenta y cualquier medio de identificación facilitada para permitirle acceder al Sitio Web.
            </p>

            <br/>
            <p align="justify">
              f. No intentará acceder, utilizar y/o manipular los datos de DN, el Sitio Web y otros Usuarios.
            </p>

            <br/>
            <p align="justify">
              g. No introducirá ni difundirá virus informáticos o cualquier otro sistema físico o informático que sea susceptible de provocar daños al Sitio Web.
            </p>

            <br/>
            <p align="justify">
              • DN no garantizan la disponibilidad y continuidad del funcionamiento de la Plataforma. En consecuencia, DN no será responsables por cualquier daño y/o perjuicio, que se pueda derivar de: (i) uso o falta de capacidad para usar la Plataforma; (ii) falta de disponibilidad o accesibilidad a la Plataforma; (iii) interrupción del funcionamiento de la Plataforma o fallos informáticos, averías, desconexiones, retrasos, o bloqueos causados por deficiencias o sobrecargas en el sistema de internet o en otros sistemas electrónicos; y, (iii) otros daños que puedan ser causados por terceros, ajenos al control de DN.
            </p>

            <br/>
            <p align="justify">
              • Bajo los términos aquí señalados, los DN no asume ninguna responsabilidad por (i) la imposibilidad de utilización del Sitio o de alguna de las páginas que lo conforman, incluyendo, pero sin limitarse a eventos tales como problemas en el servidor o en la conexión, interrupciones en su comunicación, problemas técnicos; (ii) la información que se suministra en el Sitio por los Usuarios, incluyendo, pero sin limitarse a, la referente a productos y/o servicios.
            </p>

            <br/>
            <h5 align="justify">
              7.	EXENCIÓN Y LIMITACIÓN DE RESPONSABILIDAD
            </h5>


            <br/>
            <p align="justify">
              • Al ingresar al Sito, el Usuario acepta en forma expresa que su uso se realiza bajo su exclusiva responsabilidad y riesgo.
            </p>

            <br/>
            <p align="justify">
              • El Usuario es el único responsable de la administración su inventario digital y gestión de venta de recargas digitales hacia el consumidor final. En consecuencia, DN no será responsable de las consecuencias de  gestión de venta de recargas digitales y la interacción de cualquier naturaleza entre  el Usuario y el consumidor final.
            </p>

            <br/>
            <p align="justify">
              • Sin perjuicio de lo determinado en las normas imperativas de la legislación ecuatoriana aplicable, DN no asume responsabilidad alguna ni responsabilidad derivada de cualquier daño o perjuicio, incluyendo, mas no limitado a, la pérdida de información o utilidades, existencia de virus, resultados del uso o la incapacidad para usar el material en este sitio, oportunidades de negocios perdidas, o cualquier otro daño, aun cuando el Usuario haya avisado acerca de la posibilidad de tales daños, o por cualquier reclamo de terceros, salvo lo que expresamente aquí se estipula.
            </p>

            <br/>
            <p align="justify">
              • La organización de los productos y/o servicios a los cuales se les haga publicidad en el Sitio, así como los enlaces (links) a terceros no constituye ninguna garantía, expresa o implícita de su contenido o de su adecuación para un propósito particular por parte de DN. Así, por ejemplo, cuando se sugiera la utilización de algún producto y/o servicio, es de exclusiva autonomía del Usuario decidir su utilización, y por lo tanto DN no asume ninguna responsabilidad por los perjuicios directos o indirectos, ciertos o eventuales, pasados, presentes o futuros, que se llegaren a derivar de dicha utilización.
            </p>

            <br/>
            <h5 align="justify">
              8.	PROPIEDAD INTELECTUAL
            </h5>

            <br/>
            <p align="justify">
              • El Usuario reconoce y acepta que al tener acceso al Sitio y/o los productos o servicios incluidos en él, no obtiene ninguna licencia de servicios y/o productos a través del mismo. El uso del Sitio, así como los Servicios que estén integrados está protegidos por las leyes de propiedad intelectual, pertenece y son propiedad de los Licenciantes, por tanto, con su uso, no se transfiere ningún derecho de propiedad intelectual.
            </p>

            <br/>
            <p align="justify">
              • Todos los derechos de propiedad intelectual e industrial sobre los contenidos y/ cualquier otro elemento insertado en la Plataforma, incluyendo, sin limitación, todo el material informático, gráfico, publicitario, fotográfico, de multimedia, audiovisual y/o de diseño, así como todos los contenidos, textos y bases de datos (en adelante “los Contenidos”), puestos a disposición del Establecimiento de Comercio y/o los Usuarios en el Sitio son de propiedad exclusiva de los Licenciantes o de terceros que han autorizado su uso y/o explotación. Igualmente, el uso de algunos contenidos, las normas sobre derechos de autor, marcas y por todas las normas nacionales e internacionales de propiedad de terceros se encuentra expresamente autorizado por la Ley y están protegidos por las normas aplicables.
            </p>

            <br/>
            <p align="justify">
              • Queda prohibido todo acto de copia, reproducción, modificación, creación de trabajos derivados, venta o distribución, exhibición de los Contenidos, de ninguna manera o por ningún medio, incluyendo, mas no limitado a, medios electrónicos, mecánicos, de fotocopiado, de grabación o de cualquier otra índole, sin el permiso previo por escrito del titular de los derechos de autor. En ningún caso los presentes Términos y Condiciones confieren derechos, licencias y/o autorizaciones para realizar los actos anteriormente descritos. Cualquier uso no autorizado de los contenidos constituirá una violación a los presentes términos y condiciones y a las normas vigentes sobre marcas, derechos de autor y/u otras normas de propiedad intelectual tanto nacionales e internacionales aplicables y dará lugar a las acciones civiles y penales correspondientes.
            </p>

            <br/>
            <p align="justify">
              • Bajo ningún concepto se entenderá que el acceso a la Plataforma y/o la aceptación de los Términos y Condiciones genera algún derecho de cesión a favor de los Usuarios ni de cualquier tercero.
            </p>

            <br/>
            <h5 align="justify">
              9.	MODIFICACIONES
            </h5>

            <br/>
            <p align="justify">
              • DN se reserva el derecho a realizar modificaciones a los Términos y Condiciones, en cualquier momento, como consecuencia de cambios de ley o normativos en general, así como por decisión unilateral.
            </p>

            <br/>
            <p align="justify">
              • A partir de la fecha de modificación de estos Términos y Condiciones, todas las operaciones que se celebren entre DN y el Usuario se regirán por el documento modificado. Toda comunicación relacionada a terminación anticipada, o enmienda o reforma de los términos y condiciones será puesta en conocimiento de los Usuarios de manera oportuna a través de la misma Plataforma o a través de la plataforma BEES.
            </p>

            <br/>
            <p align="justify">
              • Las modificaciones en las funcionalidades ofrecidas por el Sitio Web en atención a la necesidad de innovar y mejorar su experiencia podrán ser realizadas sin necesidad de previo aviso.
            </p>

            <br/>
            <h5 align="justify">
              10.	AUTORIZACIÓN DE TRATAMIENTO DE DATOS PERSONALES
            </h5>

            <br/>
            <p align="justify">
              • Todos los datos personales suministrados por los Usuarios para la utilización de esta Plataforma serán utilizados únicamente para el propósito de la misma por DN, cualquiera de sus compañías relacionadas y / o por cualquier agente designado por él para ayudar a ejecutar la misma. Sus datos personales serán tratados según lo dispone la Ley Orgánica de Protección de Datos Personales, la Ley de Comercio Electrónico, Firmas Electrónicas y Mensajes de Datos.
            </p>

            <br/>
            <p align="justify">
              • Los Usuarios declaran que los datos personales han sido entregados de forma absolutamente libre y voluntaria, sin ningún tipo de presión, o condición de por medio.
            </p>

            <br/>
            <p align="justify">
              • Se reitera que el Usuario de la plataforma, al ingresar, acepta expresamente haber leído y estar de acuerdo con los Términos de Uso y Política de Privacidad, manifestando que ha sido informado por parte de DN de lo siguiente:
            </p>

            <br/>
            <p align="justify">
              (i) Que DN recolectará, usará y tratará los datos personales del Usuario para las finalidades descritas en el presente documento, considerando la Política de Protección de Datos Personales de la EMPRESA, disponible en la página web;
            </p>

            <br/>
            <p align="justify">
              • (i) Que DN recolectará, usará y tratará los datos personales del Usuario para las finalidades descritas en el presente documento, considerando la Política de Protección de Datos Personales de la DN, disponible en la página web;
            </p>

            <br/>
            <p align="justify">
              • (ii) Qué es de carácter facultativo responder preguntas que versen sobre datos personales sensibles o sobre datos personales de menores;
            </p>

            <br/>
            <p align="justify">
              • (iii) Que el Usuario cuenta con todos los derechos sobre sus datos de acuerdo a la Constitución de la República del Ecuador, leyes de la materia, entre ellas, la Ley de Comercio Electrónico, Firma Electrónica y Mensajes de Datos, y a la Política de Privacidad de Datos Personales de DN que consta en la Página Web, especialmente: conocer, actualizar, rectificar y suprimir la información contenida en bases de datos; solicitar prueba de la autorización otorgada al responsable; ser informado por el responsable sobre el uso que se le ha dado a sus datos personales; revocar la autorización otorgada cuando no se respeten los principios, derechos y garantías legales y constitucionales; presentar quejas por infracciones ante la autoridad competente y acceder gratuitamente a los datos personales objeto de tratamiento, los cuales podrá ejercer siguiendo los procedimientos previstos en la Política de Protección de Datos Personales y el Aviso de Privacidad disponibles en la página web.
            </p>

            <br/>
            <p align="justify">
              • (iv) Que la DN garantiza la confidencialidad, disponibilidad, seguridad, veracidad, integridad, transparencia, acceso y circulación restringida de los datos que trata, a través de la adopción de las políticas y procedimientos establecidos para tales efectos, que el Usuario declara conocer y estar absolutamente conforme;
            </p>

            <br/>
            <p align="justify">
              • (v) Que DN se reserva el derecho de modificar su Política de Protección de Datos Personales en cualquier momento, cambio que será informado y publicado oportunamente en la página web de DN o a través de los medios que disponga para tal fin.
            </p>

            <br/>
            <p align="justify">
              • (vi) Que en caso de tener alguna inquietud o reclamo, podré contactar a DN en el correo electrónico Gabriela.paredes@ab-inbev.com.
            </p>

            <br/>
            <p align="justify">
              • (vii) Que el Usuario ha sido informado y conoce los derechos que le asisten respecto a la protección de sus datos personales, entre ellos, el derecho a conocer el uso que se haga de dicha información, su finalidad, el origen y destino, y el tiempo de vigencia de la información o data proporcionada; todo lo cual declara ha sido puesto en su conocimiento y se encuentra absolutamente conforme.
            </p>

            <br/>
            <p align="justify">
              Al utilizar la Plataforma y proporcionar sus datos, el Usuario declara que los datos personales que entrega para ser sometidos a Tratamiento son verídicos y que los ha suministrado de forma voluntaria.
            </p>

            <br/>
            <h5 align="justify">
              11.	NOTIFICACIONES
            </h5>

            <br/>
            <p align="justify">
              El Usuario declara y acepta que DN podrá realizar las notificaciones oportunas al Usuario a través de mensajes de texto, llamadas telefónicas o a la dirección de correo facilitadas por el Usuario, incluyendo el envío de mensajes con fines promocionales, publicitarios, comunicación de funcionalidades del Sitio Web. El Usuario podrá notificar a DN y solicitar el cese de la actividad promocional o publicitaria mediante una solicitud a través de la sección de Gabriela.paredes@ab-inbev.com
            </p>

            <br/>
            <h5 align="justify">
              12.	LEGISLACIÓN APLICABLE Y JURISDICCIÓN
            </h5>

            <br/>
            <p align="justify">
              • Los presentes Términos y Condiciones se regirán e interpretarán de acuerdo con las leyes de la República del Ecuador. Cualquier controversia que derive de este documento se someterá a las leyes aplicables y a los jueces competentes de acuerdo con la legislación ecuatoriana.
            </p>

            <br/>
            <p align="justify">
              • Si alguna de las disposiciones de estos Términos y Condiciones se considera inválida, ilegal o inaplicable, esto no afectará la vigencia y el efecto del resto de las disposiciones.
            </p>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
