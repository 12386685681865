<template>
  <div id="portfolio" class="our-portfolio section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 offset-lg-3 col-md-12 ">
          <div
              class="section-heading wow ">
            <h2>Confirmar recarga <span>Claro</span></h2>
          </div>
          <div>

            <div class="text-center">
              <h6>Por favor confirma los datos de la recarga</h6>
              <br/>
              <h5>{{this.datosRecarga.descripcionProducto}}</h5>
              <h5>Número {{this.datosRecarga.numeroCelular}}</h5>
            </div>
            <br/>
            <Alerta :alerta="alerta" v-if="alerta"/>
            <div class=" justify-content-center" v-if="procesando">
              <div class="main-red-button ">
                <SpinnerLink/>
              </div>
            </div>
            <div class=" justify-content-center" v-else>
              <div class="main-red-button ">
                <a class="boton-recarga" href="#" @click="confirmarRecarga"> Confirmar recarga</a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {split} from "lodash";
import Alerta from "../../../components/Alerta";
import SpinnerLink from "../../../components/SpinnerLink";
export default {
  name:"RecargaClaroForm",
  components: {
    Alerta,
    SpinnerLink
  },
  data(){
    return{
      usuario: "PRUEBAREC",
      clave: "5566",
      alerta: null,
      procesando: false
    }
  },
  created(){
    if(!this.$store.getters["auth/isLogged"]){
      this.$router.push({name: "Home"});
      return false;
    }
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async confirmarRecarga() {
      this.alerta = null;
      this.procesando = true;
      this.loaderSpinner();
      try {
        let datosRecarga = {
          "cantidad": this.datosRecarga.cantidad,
          "clave": this.clave,
          "latitud": this.datosRecarga.latitud,
          "longitud": this.datosRecarga.longitud,
          "operacion": this.datosRecarga.operacion,
          "suscriptor": this.datosRecarga.suscriptor,
          "usuario": this.usuario,
        };

        let numeroCelular = this.datosRecarga.numeroCelular;
        
        let respuesta = await this.$store.dispatch("servicios/recargar",datosRecarga);
        let trx = [];
        let fecha = "";
        if(respuesta.estado === "SUCCESS"){
          trx = split(respuesta.mensaje,"Trx:");
          fecha = respuesta.infoAdicional;
        }
        
        await this.$store.commit("servicios/setDetalleRecarga", {
          trx: trx.length>0 ? trx[1] : "",
          fecha: fecha,
          producto: datosRecarga.operacion, 
          numero: numeroCelular, 
          valor: datosRecarga.cantidad
        });
        this.$router.push({name: "RecargaExitosa"});
      } catch (error) {
        (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) 
        ? (error.response.data.errors[0].includes("Read timed out") 
          ? this.alerta = "Time out, no se recibe respuesta del servicio y no se pudo realizar la recarga" 
          : this.alerta = error.response.data.errors[0]) 
        : this.alerta = "No se pudo realizar la recarga";
      } finally{
        this.procesando = false;
        this.loader.hide();
      }
    }
  },
  computed: {
    datosRecarga() {
      return this.$store.getters["servicios/datosRecarga"] ? this.$store.getters["servicios/datosRecarga"] : [] ;
    }
  }
}
</script>

<style scoped>
.input {
  width: 100%;

  border-radius: 33px;
  background-color: #F2F1F1;
  border: none;
  outline: none;
  font-size: 30px;
  font-weight: 600;
  color: #2a2a2a;
  padding: 5px 20px ;
  margin-bottom: 20px;
  text-align: center;

}
.boton-recarga{
  width: 100% !important;
  text-align: center;
}
</style>
