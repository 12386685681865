<template>
  <SolicitudForm />
</template>

<script>
import SolicitudForm from "./components/SolicitudForm";
export default {
  name: "Solicitud",
  components: { SolicitudForm },
};
</script>

<style>
</style>