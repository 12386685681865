import axios from "axios";

const URL = "api/v1/public/clientes";

const state = {
  cliente: null
};

const getters = {
  cliente: state => state.cliente
};

const mutations = {
  setCliente: (state, cliente) => {
    state.cliente = cliente;
  },
  inicializarCliente: state => {
    state.cliente = null;
  }
};

const actions = {
  consultar: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL}?codigoCliente=${data.codigoCliente}&cedula=${data.cedula}`)
        .then(res => {
          let cliente = res.data.result;
          if(!cliente.celular){
            cliente.celular = data.celular;
          }          
          commit("setCliente", cliente);
          resolve(cliente);
        })
        .catch(error => reject(error));
    });
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
