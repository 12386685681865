import axios from "axios";

const URL = "api/v1/transacciones";

const state = {
  transacciones: null,
  detalle: []
};

const getters = {
  transacciones: state => state.transacciones,
  detalle: state => state.detalle
};

const mutations = {
  setTransacciones: (state, transacciones) => {
    state.transacciones = transacciones;
  },
  setDetalle: (state, detalle) => {
    state.detalle = detalle;
  },
  inicializarTransacciones: state => {
    state.transacciones = null;
  }
};

const actions = {
  consultar: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL}/${data.pagina}/pagina`)
        .then(res => {        
          commit("setTransacciones", res.data.result);
          resolve(res.data.result);
        })
        .catch(error => reject(error));
    });
  },
  consultarDetalle: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL}/${data.id}`)
        .then(res => {        
          commit("setDetalle", res.data.result);
          resolve(res.data.result);
        })
        .catch(error => reject(error));
    });
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
