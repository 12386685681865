<template>

  <div class="our-services section">

    <div class="container">
      <div class="row">
        <div class="col-lg-6 offset-lg-3 col-md-6 col-sm-12">
          <div class="card">
            <div class="card-body">
              <div>
                <div class="accordion-item">
                  <div class="section-heading">
                    <h3>Recuperar clave</h3>
                  </div>
                  <br/>
                  <Form @submit="submit" id="contact" class="formulario"
                        :validation-schema="formValidation" v-slot="{errors}">
                    <div class="row">
                      <div class="col-lg-12">
                        <fieldset>
                          <Field
                              type="text"
                              name="codigoCliente"
                              placeholder="Código de cliente"
                              v-model.trim="formData.codigoCliente"
                          />
                          <MensajeValidacion :mensaje="errors.codigoCliente" class="colorValidacion"/>
                        </fieldset>
                      </div>
                      <div class="col-lg-12">
                        <fieldset>
                          <Field
                              type="text"
                              name="cedula"
                              placeholder="Cédula"
                              v-model.trim="formData.cedula"
                          />
                          <MensajeValidacion :mensaje="errors.cedula" class="colorValidacion"/>
                        </fieldset>
                      </div>
                      <Alerta :alerta="alerta" v-if="alerta"/>
                      <div>
                        <div class="d-grid gap-2">
                          <button type="submit" class=" boton-aceptar">
                            Recuperar clave
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Form, Field} from "vee-validate";
import Alerta from "../../../components/Alerta";
import MensajeValidacion from "../../../components/MensajeValidacion";

export default {
  name: "RecuperarClave",
  components: {
    Form,
    Field,
    Alerta,
    MensajeValidacion
  },
  data() {
    const formValidation = {
      codigoCliente: "required:Código de cliente",
      cedula: "required:Cédula",
    };
    return {
      codigoCliente: "",
      alerta: null,
      formValidation,
      formData: {
        codigoCliente: null,
        cedula: null
      },
      formDataCopy: {}
    };
  },
  mounted() {
    this.formDataCopy = {...this.formData};
  },
  methods: {
    async submit() {
      this.alerta = null;
      try {
        await this.$store.dispatch("auth/recuperarClave", {cedula: this.formData.cedula, codigoCliente: this.formData.codigoCliente});
        this.$router.push({name: "MensajeExitoso", params:{mensaje: "Se ha enviado la nueva clave al celular registrado"}});
      } catch (error) {
        error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0 ? this.alerta = error.response.data.errors[0] : this.alerta = "No se pudo recuperar la clave"
        //this.alerta = "No se pudo recuperar la clave";
      }
    }
  },
};
</script>
<style scoped>
.boton-aceptar {
  margin-right: 10px
}
.formulario {
  background: #f3f3f3 !important;
}
.colorValidacion{
    color:black;
}
</style>
