import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'
import Verificacion from "../views/verificacion/Verificacion";
import Solicitud from "../views/solicitud/Solicitud";
import Registro from "../views/cliente/Registro";
import NoDisponible from "../views/mensaje/NoDisponible";
import OpcionesPrincipales from '../views/opciones/OpcionesPrincipales'
import RegistroExitoso from "../views/mensaje/RegistroExitoso";
import RecargaClaro from "../views/recarga/RecargaClaro";
import ConfirmarRecargaClaroForm from "../views/recarga/components/ConfirmarRecargaClaroForm";
import RecargaExitosa from "../views/mensaje/RecargaExitosa";
import SolicitudesAfiliacion from "../views/solicitudes/SolicitudesAfiliacion";
import CuentaUsuario from "../views/cuenta/CuentaUsuario";
import TransaccionesAfiliado from "../views/transacciones/TransaccionesAfiliado";
import RecuperarClave from "../views/cuenta/RecuperarClave";
import MensajeExitoso from "../views/mensaje/MensajeExitoso";
import Inventario from "../views/inventario/Inventario";
import TicketTransaccion from "../views/transacciones/TicketTransaccion";
import InicioAdmin from "../views/admin/InicioAdmin";
import Politicas from '../views/Politicas'
import Terminos from '../views/TerminosCondiciones'
import ValideFactura from '../views/factura/ValideFactura'
import CargarFacturas from '../views/factura/CargarFacturas'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        component: Verificacion
    },
    {
        path: '/verificacion',
        name: 'Verificacion',
        component: Verificacion
    },
    {
        path: '/solicitud',
        name: 'Solicitud',
        component: Solicitud
    },
    {
        path: '/registro',
        name: 'Registro',
        component: Registro
    },
    {
        path: '/recuperar-clave',
        name: 'RecuperarClave',
        component: RecuperarClave
    },
    {
        path: '/mensaje',
        name: 'Mensaje',
        component: NoDisponible
    },
    {
        path: '/registro-exitoso',
        name: 'RegistroExitoso',
        component: RegistroExitoso
    },
    {
        path: '/mensaje-exitoso',
        name: 'MensajeExitoso',
        component: MensajeExitoso
    },
    {
        path: '/inicio-admin',
        name: 'InicioAdmin',
        component: InicioAdmin,
        meta: {
            auth: true
        }
    },
    {
        path: '/inventario',
        name: 'Inventario',
        component: Inventario,
        meta: {
            auth: true
        }
    },
    {
        path: '/opciones',
        name: 'Opciones',
        component: OpcionesPrincipales,
        meta: {
            auth: true
        }
    },
    {
        path: '/recarga-claro',
        name: 'RecargaClaro',
        component: RecargaClaro,
        meta: {
            auth: true
        }
    },
    {
        path: '/confirmar-recarga-claro',
        name: 'ConfirmarRecargaClaro',
        component: ConfirmarRecargaClaroForm,
        meta: {
            auth: true
        }
    },
    {
        path: '/recarga-exitosa',
        name: 'RecargaExitosa',
        component: RecargaExitosa,
        meta: {
            auth: true
        }
    },
    {
        path: '/solicitudes-afiliacion',
        name: 'SolicitudesAfiliacion',
        component: SolicitudesAfiliacion,
        meta: {
            auth: true
        }
    },
    {
        path: '/cuenta-usuario',
        name: 'CuentaUsuario',
        component: CuentaUsuario,
        meta: {
            auth: true
        }
    },
    {
        path: '/transacciones-afiliado',
        name: 'TransaccionesAfiliado',
        component: TransaccionesAfiliado,
        meta: {
            auth: true
        }
    },
    {
        path: '/ticket-transaccion',
        name: 'TicketTransaccion',
        component: TicketTransaccion,
        meta: {
            auth: true
        }
    },
    {
        path: '/validacion-factura',
        name: 'ValideFactura',
        component: ValideFactura,
        meta: {
            auth: true
        }
    },
    {
        path: '/cargar-facturas',
        name: 'CargarFacturas',
        component: CargarFacturas,
        meta: {
            auth: true
        }
    },
    {
        path: '/politica-privacidad',
        name: 'Politicas',
        component: Politicas
    },
    {
        path: '/terminos-condiciones',
        name: 'Terminos',
        component: Terminos
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
