<template>
  <div id="portfolio" class="our-portfolio section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 offset-lg-3 col-md-12 ">
          <div
              class="section-heading wow ">
            <h2>Valide su factura <span>Claro</span></h2>
          </div>
          <form class="text-center">

            <div class="text-center">
              <input
                  type="text"
                  name="numeroPedido"
                  placeholder="Número pedido"
                  class="input"
                  required
                  v-model="numeroPedido"
              />
            </div>

            <div class="row" v-if="mensaje">
              <div class="col-lg-12">
                <div
                    class="section-heading wow bounceIn"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s">
                  <h5>{{ mensaje }}</h5>
                </div>
              </div>

            </div>

            <div class=" justify-content-center">
              <div class="main-red-button ">
                <a class="boton-recarga" href="#" @click="consultarFactura"> Consultar</a>
              </div>
            </div>
            <br/>
            <ol class="list-group list-group-numbered" v-if="mostrarTrackingFactura">
              <li class="list-group-item d-flex justify-content-between align-items-start" v-for="(item,index) in trackingFactura" :key="index">
                <div class="ms-2 me-auto">
                  <div class="fw-bold alineacionLeft">Estado</div>
                  {{item.estado.estado}}
                </div>
                <span class="badge bg-info rounded-pill">{{item.fecha}}</span>
              </li>
            </ol>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "ValidarFactura",
  data(){
    return {
      mensaje: null,
      mostrarTrackingFactura: false,
      numeroPedido: null,
      trackingFactura: []
    }
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$router.push({name: "Home"});
      return false;
    }
  },
  methods:{
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarFactura() {
      this.mensaje = null;
      this.mostrarTrackingFactura = false;
      this.trackingFactura = [];
      this.loaderSpinner();
      try {
        let numeroPedido = this.numeroPedido;
        let trackingFactura = await this.$store.dispatch("factura/consultarFactura", {numeroPedido: numeroPedido});
        if(trackingFactura.length > 0){
          let trackingFormateado = [];
          let estadoColor = {};
          trackingFactura.map(item => {
            item.estadoFactura.includes("08") ?
            estadoColor = {estado: "Aprobado", color: "primary"} :
            item.estadoFactura.includes("09") ? estadoColor = {estado: "En ruta", color: ""} :
            estadoColor = {estado: "Anulado", color: "danger"}
            trackingFormateado.push(
              {
                //estado: item.estadoFactura.includes("08") ? "Aprobado" : item.estadoFactura.includes("09") ? "En ruta" : "Anulado",
                estado: estadoColor,
                fecha: item.fechaCarga
              })
          });
          this.trackingFactura = trackingFormateado;
          this.mostrarTrackingFactura = true;
        }else{
          this.mensaje = "El pedido ingresado no tiene datos";
        }
      } catch (error) {
        this.loader.hide();
        this.mensaje = "No se pudo recuperar la información";
      } finally {
        this.loader.hide();
      }
    }
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"] ? this.$store.getters["auth/isLogged"] : false;
    },
  }
}
</script>

<style scoped>
.input {
  width: 100%;

  border-radius: 33px;
  background-color: #F2F1F1;
  border: none;
  outline: none;
  font-size: 22px;
  font-weight: 500;
  color: #2a2a2a;
  padding: 5px 20px;
  margin-bottom: 20px;
  text-align: center;

}

.boton-recarga {
  width: 100% !important;
  text-align: center;
}


.alineacionLeft{
  text-align: start;
}

.bg-info{
  background: #f19b32 !important;
}

</style>
