<template>
  <div>
    <RecargaClaroForm/>
  </div>
</template>
<script>
import RecargaClaroForm from "./components/RecargaClaroForm";
export default {
  components: {RecargaClaroForm}
}
</script>