import axios from "axios";

const URL = "api/v1/registro";

const state = {
  registro: null
};

const getters = {
  registro: state => state.registro
};

const mutations = {
  setRegistro: (state, registro) => {
    state.registro = registro;
  },
  inicializarRegistro: state => {
    state.registro = null;
  }
};

const actions = {
  crear: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL}`, data)
        .then(res => {
          commit("setRegistro", res.data);
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
