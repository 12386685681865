<template>
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.25s">
            <p>© Copyright 2021 Dinadec. Todos los derechos reservados.

              <br>Desarrollado por: <a rel="nofollow" href="http://www.scientialabs.club">Scientialabs</a></p>
          </div>
        </div>
      </div>
    </footer>
</template>