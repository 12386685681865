import { createStore } from 'vuex'
import auth from "./modules/auth";
import registro from "./modules/registro";
import cliente from "./modules/cliente";
import productos from "./modules/productos";
import servicios from "./modules/servicios";
import solicitudesAfiliacion from "./modules/solicitudesAfiliacion"
import transaccionesAfiliado from "./modules/transaccionesAfiliado";
import factura from "./modules/factura";

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    registro,
    cliente,
    productos,
    servicios,
    solicitudesAfiliacion,
    transaccionesAfiliado,
    factura
  }
})
