<template>
  <div>
    <Mensaje mensaje="No está disponible para otras operadoras" ruta="Home"/>
  </div>
</template>

<script>
import Mensaje from "../../components/Mensaje";
export default {
  components: {Mensaje}
};
</script>