<template>
  <div class=" ticket-width">

    <div class="card shadow p-3 mb-5 bg-white rounded">
      <div class="card-body">
        <div class="text-center">
          ***********************
        </div>
        <div class="text-lg-start text-md-start">
          <p class="label-ticket">Trx: {{trx}}</p>
          <p class="label-ticket">Fecha: {{fecha}}</p>
          <p class="label-ticket">Producto: {{producto}}</p>
          <p class="label-ticket">Teléfono: {{numero}}</p>
          <p class="label-ticket">Valor: {{valor}}</p>
        </div>
        <br/>
        <div class="text-center label-ticket">
          www.serviciosbees.com
          Gracias por su compra
        </div>
        <br/>
        <div class="text-center">
          ***********************
        </div>
      </div>
    </div>
  </div>


</template>
<script>
export default {
  name: "Ticket",
  props:["trx", "fecha", "producto", "numero", "valor"],
  created(){
    if(!this.$store.getters["auth/isLogged"]){
      this.$router.push({name: "Home"});
      return false;
    }
  }
}
</script>
<style>
.label-ticket{
  margin-bottom: -10px;
  font-size: 12px;
}

.ticket-width {
  width: 250px;
  text-align: center;
}
</style>
