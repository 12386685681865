<template>
  <div id="portfolio" class="our-portfolio section">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-3">
                <div class="section-heading  wow bounceIn" data-wow-duration="1s" data-wow-delay="0.2s">
                <h2>Detalle de la recarga</h2>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center">
        <Ticket :trx="datosTicket.trx" :fecha="datosTicket.fecha" :producto="datosTicket.operacionDescripcion" :numero="datosTicket.numero" :valor="datosTicket.valor"/>
        </div>
        <div class="d-flex justify-content-center">
            <div class="main-red-button">
            <a href="#" @click="$router.go(-1)">Regresar</a>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Ticket from "../../components/Ticket";
export default {
  components: {Ticket},
  computed:{
    datosTicket(){
      return this.$store.getters["servicios/detalleRecarga"] ? this.$store.getters["servicios/detalleRecarga"] : []
    }
  }
};
</script>
