import axios from "axios";
import { findIndex } from "lodash";

const URL = "api/v1/solicitudes";

const state = {
    solicitudesAfiliacion: null,
    solicitudAprobada: null
};

const getters = {
    solicitudesAfiliacion: state => state.solicitudesAfiliacion
};

const mutations = {
    setSolicitudesAfiliacion: (state, solicitudesAfiliacion) => {
        state.solicitudesAfiliacion = solicitudesAfiliacion;
    },
    setSolicitudAprobada: (state, solicitudAprobada) => {
        state.solicitudAprobada = solicitudAprobada
    }
};


const actions = {
    consultar: ({commit}) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${URL}?estadoSolicitud=PENDIENTE`)
                .then(res => {
                    let solicitudesAfiliacion = res.data.result;
                    commit("setSolicitudesAfiliacion", solicitudesAfiliacion);
                    resolve(solicitudesAfiliacion);
                })
                .catch(error => reject(error));
        });
    },
    aprobar: ({commit, state}, data) => {
        return new Promise((resolve, reject) => {
            axios
                .put(`${URL}/${data.id}/aprobar`)
                .then(res => {
                    let solicitudAprobada = res.data.result;
                    commit("setSolicitudAprobada", solicitudAprobada);
                    let indice = findIndex(state.solicitudesAfiliacion, ["id", res.data.result.id]);
                    let solicitudesAfiliacionActualizadas = [...state.solicitudesAfiliacion];
                    solicitudesAfiliacionActualizadas.splice(indice, 1);
                    commit("setSolicitudesAfiliacion", solicitudesAfiliacionActualizadas);
                    resolve(solicitudAprobada);
                })
                .catch(error => reject(error));
        });
    },

};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
