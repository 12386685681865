import axios from "axios";

const URL = "api/v1/recargas-claro";

const state = {
  datosRecarga: null,
  inventario: null,
  detalleRecarga: null
};

const getters = {
  datosRecarga: state => state.datosRecarga,
  inventario: state => state.inventario,
  detalleRecarga: state => state.detalleRecarga
};

const mutations = {
  setDatosRecarga: (state, datosRecarga) => {
    state.datosRecarga = datosRecarga;
  },
  setInventario: (state, inventario) => {
    state.inventario = inventario;
  },
  inicializarDatosRecarga: state => {
    state.datosRecarga = null;
    state.inventario = null;
  },
  setDetalleRecarga: (state, detalleRecarga) => {
    state.detalleRecarga = detalleRecarga;
  }
};

const actions = {
  recargar: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL}`, data)
        .then(res => {
          //commit("inicializarDatosRecarga");
          commit("setDatosRecarga",res.data.result);
          resolve(res.data.result);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  consultarInventario: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL}/consulta`, data)
        .then(res => {
          commit("setInventario");
          resolve(res.data.result);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  consultarRecarga: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL}/consulta-recarga`, data)
        .then(res => {
          commit("inicializarDatosRecarga");
          resolve(res.data.result);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
