<template>
  <div>
    <!--<Navbar/>-->

    <div id="services" class="our-services">

      <div class="container" v-if="isLogged">
        <div class="row">
          <div
              class="col-lg-6 align-self-center wow fadeInLeft"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
          >
            <div class="left-image">
              <img src="../../assets/images/services-left-image.png" alt=""/>
            </div>
          </div>
          <div
              class="col-lg-6 wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
          >
            <div class="section-heading">
              <h2>Solicitudes de afiliación <span>Claro</span></h2>
            </div>

            <p>
              Solicitudes pendientes de aprobación
            </p>

            <br/>
            <table class="table">
              <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Cliente</th>
                <th scope="col">Celular</th>
                <th scope="col"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="solicitud in solicitudesAfiliacion" :key="solicitud.id">
                <td>{{ solicitud.id }}</td>
                <td>{{ solicitud.codigoCliente }}</td>
                <td>{{ solicitud.celular }}</td>
                <td class="main-blue-button"><a href="#" class="boton-confirmacion" @click="aprobar(solicitud.id)">Aprobar</a>
                </td>
              </tr>

              </tbody>
            </table>

            <button type="button" name="Exportar" class="btn btn-secondary" @click="csvExport(solicitudesAfiliacion)">
              Descargar
            </button>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "SolicitudesAfiliacion",
  components: {},
  data() {
    return {}
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$router.push({name: "Home"});
      return false;
    }
    this.consultar()
  },
  methods: {
    async consultar() {
      try {
        await this.$store.dispatch("solicitudesAfiliacion/consultar")
      } catch (error) {
        console.log(error)
      }
    },
    async aprobar(idSolicitud) {
      try {
        await this.$store.dispatch("solicitudesAfiliacion/aprobar", {id: idSolicitud})
      } catch (error) {
        console.log(error)
      }
    },
    async csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      let newArrData = [];
      await arrData.map(item => {
        newArrData.push(
          {
            codigoCliente: item.codigoCliente,
            celular: item.celular
          }
        )
      });

      csvContent += [
        Object.keys(newArrData[0]).join(","),
        ...newArrData.map(item => Object.values(item).join(","))
      ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    }
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"] ? this.$store.getters["auth/isLogged"] : false;
    },
    solicitudesAfiliacion() {
      return this.$store.getters["solicitudesAfiliacion/solicitudesAfiliacion"] ? this.$store.getters["solicitudesAfiliacion/solicitudesAfiliacion"] : []
    }
  }
}
</script>
