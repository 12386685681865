<template>
  <div>
    <RecuperarClaveForm/>
  </div>
</template>
<script>
import RecuperarClaveForm from "./components/RecuperarClaveForm";
export default {
  components: {RecuperarClaveForm}
}
</script>