<template>
  <div class="error">
    <small>{{ mensaje }}</small>
  </div>
</template>
<script>
export default {
  props: ['mensaje']
}
</script>
<style>
.error{
  color: #F5FC0A;
  font-weight: normal !important;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: -20px;
  margin-left: 10px;
}
</style>
