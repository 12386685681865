import axios from "axios";

const URL = "api/v1/productos-claro";

const state = {
  productos: null
};

const getters = {
  productos: state => state.productos
};

const mutations = {
  setProductos: (state, productos) => {
    state.productos = productos;
  },
  inicializarProductos: state => {
    state.productos = null;
  }
};

const actions = {
  consultar: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL}?operacion=${data.operacion}`)
        .then(res => {        
          commit("setProductos", res.data.result);
          resolve(res.data.result);
        })
        .catch(error => reject(error));
    });
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
