import axios from "axios";

const state = {
  token: null,
  user: null,
  isLogged: false
};

const getters = {
  isLogged: state => state.token !== null,
  user: state => state.user,
  token: state => state.token,
};

const mutations = {
  setToken: (state, token) => {
    state.token = token;
  },
  setUser: (state, userData) => {
    state.user = userData;
  },
  clearAuthData: state => {
    state.token = null;
    state.user = null;
  }
};

const actions = {
  login: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post("api/v1/auth/login", {
          clave: data.clave,
          codigoCliente: data.codigoCliente 
        })
        .then(res => {
          window.sessionStorage.setItem(
            "_token_recargas",
            res.data.result.token
          );
          commit("setToken", res.data.result.token);
          resolve(res.data.result.token);
        })
        .catch(error => reject(error));
    });
  },
  consultarDatosUsuario: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get("api/v1/usuarios")
        .then(res => {
          commit("setUser", res.data.result);
          resolve(res.data.result);
        })
        .catch(error => reject(error));
    });
  },
  logout: ({ commit }) => {
    try {
      window.sessionStorage.removeItem("_token_recargas");
      window.sessionStorage.removeItem("_recargas_data");
      commit("clearAuthData");
      return true;
    } catch (error) {
      console.log(error);
    }
  },
  recuperarClave: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`api/v1/auth/recuperar-clave`, data)
        .then(res => {
          commit("clearAuthData");
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  cambiarClave: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`api/v1/usuarios/cambiar-clave`, data)
        .then(res => {
          commit("clearAuthData");
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  cambiarNumeroCelular: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`api/v1/usuarios/cambiar-numero`, data)
        .then(res => {
          commit("clearAuthData");
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
