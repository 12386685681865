<template>
  <div id="portfolio" class="our-portfolio section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 offset-lg-3 col-md-12 ">
          <div
              class="section-heading wow ">
            <h2>Fomulario de recarga <span>Claro</span></h2>
          </div>
          <form class="text-center">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="operaciones" id="operaciones1" value="RECARGA_PINES" @click="cargarProductos('RECARGA_PINES')">
              <label class="form-check-label label-recarga" for="operaciones1">Minutos</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="operaciones" id="operaciones2" value="RECARGA_DATOS" @click="cargarProductos('RECARGA_DATOS')">
              <label class="form-check-label label-recarga" for="operaciones2">Megas</label>
            </div>
            <table class="table table-borderless">
              <tbody>
                <tr v-for="producto in productos" :key="producto.id">
                  <td>
                    <input class="form-check-input" type="radio" :name="producto.id" :value="producto.cantidad" @click="seleccionarProducto(producto)">
                  </td>
                  <td class="label-recarga text-lg-start valor">$ {{ producto.cantidad }} </td>
                  <td class="label-recarga text-lg-start">{{ producto.descripcion }}</td>
                </tr>
              </tbody>
            </table>
            <div class="col-lg-12" v-if="mostrarCantidad">
              <fieldset>
                <input
                  type="number"
                  name="cantidad"
                  class="input"
                  placeholder="Valor recarga"
                  required
                  min="1"
                  v-model.trim="cantidad"
                />
              </fieldset>
            </div>
            <div class="text-center">
              <input
                type="text"
                name="suscriptor"
                placeholder="Número celular"
                class="input"
                required
                v-model.trim="suscriptor"
              />
            </div>
            <Alerta :alerta="alerta" v-if="alerta"/>
            <div class=" justify-content-center" v-if="procesando">
              <div class="main-red-button ">
                <SpinnerLink/>
              </div>
            </div>
            <div class=" justify-content-center" v-else>
              <div class="main-red-button ">
                <a class="boton-recarga" href="#" @click="recargar"> Recargar</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Alerta from "../../../components/Alerta";
import SpinnerLink from "../../../components/SpinnerLink";
export default {
  name:"RecargaClaroForm",
  components: {
    Alerta,
    SpinnerLink,
  },
  data(){
    return{
      latitud: "",
      longitud: "",
      operacion: "",
      cantidad: null,
      suscriptor: "",
      descripcionProducto: "",
      alerta: null,
      procesando: false,
      mostrarCantidad: false
    }
  },
  created(){
    if(!this.$store.getters["auth/isLogged"]){
      this.$router.push({name: "Home"});
      return false;
    }
    this.$store.commit("productos/inicializarProductos");
    this.cargarCoordenadas();
  },
  methods: {
    async cargarProductos(operacion){
      this.mostrarCantidad = false;
      this.cantidad = null;
      this.descripcionProducto = "";
      this.operacion = operacion;
      if(operacion === "RECARGA_PINES"){
        this.mostrarCantidad = true;
        this.descripcionProducto = "Recarga de saldo";
        this.$store.commit("productos/setProductos",null);
        return false;
      }
      try {
        await this.$store.dispatch("productos/consultar",{operacion: operacion})
      } catch (error) {
        console.log(error)
      }
    },
    async cargarCoordenadas(){
      this.latitud = "2 09 19.639s";      
      this.longitud = "78 46 22.343W";
    },
    seleccionarProducto(producto){
      this.cantidad = producto.cantidad;
      this.descripcionProducto = producto.descripcion;
    },
    async recargar() {
      try {
        this.alerta = null;

        if(this.operacion === "RECARGA_PINES"){
          if(!Number.isInteger(parseFloat(this.cantidad))){
            this.alerta = "Por favor ingrese un valor correcto de recarga, solo se permiten valores enteros";
            return false;
          }
          if(this.cantidad  <= 0){
            this.alerta = "Por favor ingrese un valor mayor a 0 para la recarga";
            return false;
          }
        }else{
          if(this.cantidad  <= 0){
            this.alerta = "Por favor seleccione una de las opciones para la recarga";
            return false;
          }
        }

        if(this.descripcionProducto.length===0){
          this.alerta = "Por favor seleccione los minitos o megas para la recarga";
          return false;
        }
        if(this.suscriptor.length!==10){
          this.alerta = "Por favor revise el número celular";
          return false;
        }

        if(this.operacion === "RECARGA_PINES"){
          this.descripcionProducto = "Recarga de $" + this.cantidad;
        }

        let datosRecarga = {
          "cantidad": this.cantidad,
          "clave": "",
          "latitud": "2 09 19.639s",
          "longitud": "78 46 22.343W",
          "operacion": this.operacion,
          "suscriptor": "593"+this.suscriptor.substring(1),
          "usuario": "",
          "descripcionProducto": this.descripcionProducto,
          "numeroCelular": this.suscriptor, 
        };
        this.procesando = true;
        await this.$store.commit("servicios/setDatosRecarga",datosRecarga);
        this.$router.push({name: "ConfirmarRecargaClaro"});
      } catch (error) {
        this.alerta = "No se pudo continuar con la recarga, por favor intente nuevamente";
      }finally{
        this.procesando = false;
      }
    }
  },
  computed: {
    productos() {
      return this.$store.getters["productos/productos"];
    }
  },
  watch:{
    cantidad: function(value){
      this.cantidad = parseInt(value);
    }
  }
}
</script>

<style scoped>
.input {
  width: 100%;

  border-radius: 33px;
  background-color: #F2F1F1;
  border: none;
  outline: none;
  font-size: 22px;
  font-weight: 500;
  color: #2a2a2a;
  padding: 5px 20px ;
  margin-bottom: 20px;
  text-align: center;

}
.boton-recarga{
  width: 100% !important;
  text-align: center;
}
.label-recarga {
  font-size: 18px;
}
.valor{
  width: 15%;
}


</style>
