<template>
<div>
  <div id="services" class="our-services">
    <div class="container" v-if="isLogged">
      <div class="row">
        <div
            class="col-lg-6 align-self-center wow fadeInLeft"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
        >
          <div class="left-image">
            <img src="../../assets/images/services-left-image.png" alt=""/>
          </div>
        </div>
        <div
            class="col-lg-6 wow fadeInRight"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
        >
          <div class="section-heading">
            <h2>Recargas realizadas</h2>
          </div>
          <br/>
          <div class="row" v-if="error">
            <div class="col-lg-12">
              <div
                  class="section-heading wow bounceIn"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s">
                <h5>No se pudo consultar el detalle de la recarga</h5>
              </div>
            </div>
          </div>
          <br/>
          <table class="table" v-if="transacciones && transacciones.length > 0">
            <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Celular</th>
              <th scope="col">Valor Recarga</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="transaccion in transacciones" :key="transaccion.id">
              <td>{{ transaccion.id }}</td>
              <td>{{ transaccion.suscriptor }}</td>
              <td>{{ transaccion.cantidad }}</td>
              <td class="main-blue-button"><a href="#" class="boton-confirmacion" @click="consultarDetalle(transaccion)">Detalle</a></td>
            </tr>

            </tbody>
          </table>

          <div class="main-red-button" v-if="habilitarConsultar">
              <a href="#" @click="consultar">Volver a consultar</a>
            </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import {split} from "lodash";
export default {
  name: "TransaccionesAfiliado",
  data() {
    return {
      transacciones: [],
      mensaje: "",
      habilitarConsultar: false,
      error: false
    }
  },
  created() {
    if(!this.$store.getters["auth/isLogged"]){
      this.$router.push({name: "Home"});
      return false;
    }
    this.consultar()
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    inicializar(){
      this.transacciones = [];
      this.mensaje = "";
      this.habilitarConsultar = false;
    },
    async consultar() {
      this.loaderSpinner();
      this.inicializar();
      try {
        this.transacciones = await this.$store.dispatch("transaccionesAfiliado/consultar",{pagina: 1})
      } catch (error) {
        this.mensaje = "No se pudo cargar la información de las recargas realizadas, por favor intente nuevamente";
        this.habilitarConsultar = true;
      } finally {
        this.loader.hide();
      }
    },
    async consultarDetalle(transaccion) {
      this.loaderSpinner();
      this.error = false;
      try {
        let parametros = {
          "clave": "5566",
          "externalTransactionId": transaccion.idTransaccion,
          "offerId": transaccion.operacion,
          "usuario": "PRUEBAREC"
        }
        let respuesta = await this.$store.dispatch("servicios/consultarRecarga", parametros);
        if(respuesta.estado === "SUCCESS"){
          let trx = [];
          trx = split(transaccion.mensaje,"Trx:");
          await this.$store.commit("servicios/setDetalleRecarga", {
            trx: trx.length>0 ? trx[1] : "",
            fecha: transaccion.fecha,
            producto: transaccion.operacion,
            numero: transaccion.suscriptor,
            valor: transaccion.cantidad,
            operacionDescripcion : transaccion.operacionDescripcion
          });
          this.$router.push({name: "TicketTransaccion"});
        }
      } catch (error) {
        this.error = true;
      } finally {
        this.loader.hide();
      }
    }
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"] ? this.$store.getters["auth/isLogged"] : false;
    }
  }
}
</script>
<style>
.texto-centrado {
  text-align: center;
}
</style>
